export const FETCH_BOOKINGS_START = 'FETCH_BOOKINGS_START';
export const FETCH_BOOKINGS_SUCCESS = 'FETCH_BOOKINGS_SUCCESS';
export const FETCH_BOOKINGS_FAILED = 'FETCH_BOOKINGS_FAILED';

export const DELETE_BOOKING_START = 'DELETE_BOOKING_START';
export const DELETE_BOOKING_SUCCESS = 'DELETE_BOOKING_SUCCESS';
export const DELETE_BOOKING_FAILED = 'DELETE_BOOKING_FAILED';

export const UPDATE_BOOKING_START = 'UPDATE_BOOKING_START';
export const UPDATE_BOOKING_SUCCESS = 'UPDATE_BOOKING_SUCCESS';
export const RESET_UPDATE_BOOKING_SUCCESS = 'RESET_UPDATE_BOOKING_SUCCESS';
export const UPDATE_BOOKING_FAILED = 'UPDATE_BOOKING_FAILED';

export const CREATE_BOOKING_START = 'CREATE_BOOKING_START';
export const CREATE_BOOKING_SUCCESS = 'CREATE_BOOKING_SUCCESS';
export const RESET_CREATE_BOOKING_SUCCESS = 'RESET_CREATE_BOOKING_SUCCESS';
export const CREATE_BOOKING_FAILED = 'CREATE_BOOKING_FAILED';

export const FILTER_BOOKINGS_START = 'FILTER_BOOKINGS_START';
export const FILTER_BOOKINGS_SUCCESS = 'FILTER_BOOKINGS_SUCCESS';
export const FILTER_BOOKINGS_FAILED = 'FILTER_BOOKINGS_FAILED';

export const FETCH_RETURNS_START = 'FETCH_RETURNS_START';
export const FETCH_RETURNS_SUCCESS = 'FETCH_RETURNS_SUCCESS';
export const FETCH_RETURNS_FAILED = 'FETCH_RETURNS_FAILED';

export const DELETE_RETURN_START = 'DELETE_RETURN_START';
export const DELETE_RETURN_SUCCESS = 'DELETE_RETURN_SUCCESS';
export const DELETE_RETURN_FAILED = 'DELETE_RETURN_FAILED';

export const UPDATE_RETURN_START = 'UPDATE_RETURN_START';
export const UPDATE_RETURN_SUCCESS = 'UPDATE_RETURN_SUCCESS';
export const RESET_UPDATE_RETURN_SUCCESS = 'RESET_UPDATE_RETURN_SUCCESS';
export const UPDATE_RETURN_FAILED = 'UPDATE_RETURN_FAILED';

export const CREATE_RETURN_START = 'CREATE_RETURN_START';
export const CREATE_RETURN_SUCCESS = 'CREATE_RETURN_SUCCESS';
export const RESET_CREATE_RETURN_SUCCESS = 'RESET_CREATE_RETURN_SUCCESS';
export const CREATE_RETURN_FAILED = 'CREATE_RETURN_FAILED';

export const FILTER_RETURNS_START = 'FILTER_RETURNS_START';
export const FILTER_RETURNS_SUCCESS = 'FILTER_RETURNS_SUCCESS';
export const FILTER_RETURNS_FAILED = 'FILTER_RETURNS_FAILED';

export const FETCH_TOTAL_BOOKINGS_START = 'FETCH_TOTAL_BOOKINGS_START';
export const FETCH_TOTAL_BOOKINGS_SUCCESS = 'FETCH_TOTAL_BOOKINGS_SUCCESS';
export const FETCH_TOTAL_BOOKINGS_FAILED = 'FETCH_TOTAL_BOOKINGS_FAILED';

export const FETCH_CALENDAR_BOOKINGS_START = 'FETCH_CALENDAR_BOOKINGS_START';
export const FETCH_CALENDAR_BOOKINGS_SUCCESS = 'FETCH_CALENDAR_BOOKINGS_SUCCESS';
export const FETCH_CALENDAR_BOOKINGS_FAILED = 'FETCH_CALENDAR_BOOKINGS_FAILED';

export const DELETE_CALENDAR_BOOKING_START = 'DELETE_CALENDAR_BOOKING_START';
export const DELETE_CALENDAR_BOOKING_SUCCESS = 'DELETE_CALENDAR_BOOKING_SUCCESS';
export const DELETE_CALENDAR_BOOKING_FAILED = 'DELETE_CALENDAR_BOOKING_FAILED';

export const FETCH_SERVICES_START = 'FETCH_SERVICES_START';
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICES_FAILED = 'FETCH_SERVICES_FAILED';

export const FILTER_SERVICES_START = 'FILTER_SERVICES_START';
export const FILTER_SERVICES_SUCCESS = 'FILTER_SERVICES_SUCCESS';
export const FILTER_SERVICES_FAILED = 'FILTER_SERVICES_FAILED';

export const FETCH_SERVICES_BY_LOCATION_START = 'FETCH_SERVICES_BY_LOCATION_START';
export const FETCH_SERVICES_BY_LOCATION_SUCCESS = 'FETCH_SERVICES_BY_LOCATION_SUCCESS';
export const FETCH_SERVICES_BY_LOCATION_FAILED = 'FETCH_SERVICES_BY_LOCATION_FAILED';

export const SEARCH_SERVICES_START = 'SEARCH_SERVICES_START';
export const SEARCH_SERVICES_SUCCESS = 'SEARCH_SERVICES_SUCCESS';
export const SEARCH_SERVICES_FAILED = 'SEARCH_SERVICES_FAILED';

export const UPDATE_SERVICE_START = 'UPDATE_SERVICE_START';
export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS';
export const RESET_UPDATE_SERVICE_SUCCESS = 'RESET_UPDATE_SERVICE_SUCCESS';
export const UPDATE_SERVICE_FAILED = 'UPDATE_SERVICE_FAILED';

export const CREATE_SERVICE_START = 'CREATE_SERVICE_START';
export const CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS';
export const RESET_CREATE_SERVICE_SUCCESS = 'RESET_CREATE_SERVICE_SUCCESS';
export const CREATE_SERVICE_FAILED = 'CREATE_SERVICE_FAILED';

export const DELETE_SERVICE_START = 'DELETE_SERVICE_START';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_FAILED = 'DELETE_SERVICE_FAILED';

export const FETCH_PRODUCTS_START = 'FETCH_PRODUCTS_START';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILED = 'FETCH_PRODUCTS_FAILED';

export const DELETE_PRODUCT_START = 'DELETE_PRODUCT_START';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILED = 'DELETE_PRODUCT_FAILED';

export const SEARCH_PRODUCTS_START = 'SEARCH_PRODUCTS_START';
export const SEARCH_PRODUCTS_SUCCESS = 'SEARCH_PRODUCTS_SUCCESS';
export const SEARCH_PRODUCTS_FAILED = 'SEARCH_PRODUCTS_FAILED';

export const FILTER_PRODUCTS_START = 'FILTER_PRODUCTS_START';
export const FILTER_PRODUCTS_SUCCESS = 'FILTER_PRODUCTS_SUCCESS';
export const FILTER_PRODUCTS_FAILED = 'FILTER_PRODUCTS_FAILED';

export const UPDATE_PRODUCT_START = 'UPDATE_PRODUCT_START';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILED = 'UPDATE_PRODUCT_FAILED';

export const CREATE_PRODUCT_START = 'CREATE_PRODUCT_START';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const RESET_CREATE_PRODUCT_SUCCESS = 'RESET_CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILED = 'CREATE_PRODUCT_FAILED';

export const FETCH_DEALS_START = 'FETCH_DEALS_START';
export const FETCH_DEALS_SUCCESS = 'FETCH_DEALS_SUCCESS';
export const FETCH_DEALS_FAILED = 'FETCH_DEALS_FAILED';

export const DELETE_DEAL_START = 'DELETE_DEAL_START';
export const DELETE_DEAL_SUCCESS = 'DELETE_DEAL_SUCCESS';
export const DELETE_DEAL_FAILED = 'DELETE_DEAL_FAILED';

export const UPDATE_DEAL_START = 'UPDATE_DEAL_START';
export const UPDATE_DEAL_SUCCESS = 'UPDATE_DEAL_SUCCESS';
export const RESET_UPDATE_DEAL_SUCCESS = 'RESET_UPDATE_DEAL_SUCCESS';
export const UPDATE_DEAL_FAILED = 'UPDATE_DEAL_FAILED';

export const CREATE_DEAL_START = 'CREATE_DEAL_START';
export const CREATE_DEAL_SUCCESS = 'CREATE_DEAL_SUCCESS';
export const RESET_CREATE_DEAL_SUCCESS = 'RESET_CREATE_DEAL_SUCCESS';
export const CREATE_DEAL_FAILED = 'CREATE_DEAL_FAILED';

export const SEARCH_DEALS_START = 'SEARCH_DEALS_START';
export const SEARCH_DEALS_SUCCESS = 'SEARCH_DEALS_SUCCESS';
export const SEARCH_DEALS_FAILED = 'SEARCH_DEALS_FAILED';

export const FILTER_DEALS_START = 'FILTER_DEALS_START';
export const FILTER_DEALS_SUCCESS = 'FILTER_DEALS_SUCCESS';
export const FILTER_DEALS_FAILED = 'FILTER_DEALS_FAILED';


export const FETCH_LOCATIONS_START = 'FETCH_LOCATIONS_START';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_FAILED = 'FETCH_LOCATIONS_FAILED';

export const FETCH_CATEGORIES_START = 'FETCH_CATEGORIES_START';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILED = 'FETCH_CATEGORIES_FAILED';

export const FETCH_EMPLOYEES_START = 'FETCH_EMPLOYEES_START';
export const FETCH_EMPLOYEES_SUCCESS = 'FETCH_EMPLOYEES_SUCCESS';
export const FETCH_EMPLOYEES_FAILED = 'FETCH_EMPLOYEES_FAILED';

export const FETCH_EMPLOYEES_DATA_START = 'FETCH_EMPLOYEES_DATA_START';
export const FETCH_EMPLOYEES_DATA_SUCCESS = 'FETCH_EMPLOYEES_DATA_SUCCESS';
export const FETCH_EMPLOYEES_DATA_FAILED = 'FETCH_EMPLOYEES_DATA_FAILED';

export const DELETE_EMPLOYEE_DATA_START = 'DELETE_EMPLOYEE_DATA_START';
export const DELETE_EMPLOYEE_DATA_SUCCESS = 'DELETE_EMPLOYEE_DATA_SUCCESS';
export const DELETE_EMPLOYEE_DATA_FAILED = 'DELETE_EMPLOYEE_DATA_FAILED';

export const SEARCH_EMPLOYEES_DATA_START = 'SEARCH_EMPLOYEES_DATA_START';
export const SEARCH_EMPLOYEES_DATA_SUCCESS = 'SEARCH_EMPLOYEES_DATA_SUCCESS';
export const SEARCH_EMPLOYEES_DATA_FAILED = 'SEARCH_EMPLOYEES_DATA_FAILED';

export const UPDATE_EMPLOYEE_DATA_START = 'UPDATE_EMPLOYEE_DATA_START';
export const UPDATE_EMPLOYEE_DATA_SUCCESS = 'UPDATE_EMPLOYEE_DATA_SUCCESS';
export const UPDATE_EMPLOYEE_DATA_FAILED = 'UPDATE_EMPLOYEE_DATA_FAILED';

export const ADD_EMPLOYEE_DATA_START = 'ADD_EMPLOYEE_DATA_START';
export const ADD_EMPLOYEE_DATA_SUCCESS = 'ADD_EMPLOYEE_DATA_SUCCESS';
export const ADD_EMPLOYEE_DATA_FAILED = 'ADD_EMPLOYEE_DATA_FAILED';

export const FETCH_ROLES_START = 'FETCH_ROLES_START';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAILED = 'FETCH_ROLES_FAILED';

export const FETCH_CUSTOMERS_START = 'FETCH_CUSTOMERS_START';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILED = 'FETCH_CUSTOMERS_FAILED';

export const SEARCH_CUSTOMERS_START = 'SEARCH_CUSTOMERS_START';
export const SEARCH_CUSTOMERS_SUCCESS = 'SEARCH_CUSTOMERS_SUCCESS';
export const SEARCH_CUSTOMERS_FAILED = 'SEARCH_CUSTOMERS_FAILED';

export const ADD_CUSTOMER_START = 'ADD_CUSTOMER_START';
export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS';
export const RESET_ADD_CUSTOMER_SUCCESS = 'RESET_ADD_CUSTOMER_SUCCESS';
export const ADD_CUSTOMER_FAILED = 'ADD_CUSTOMER_FAILED';

export const FETCH_COUPONS_START = 'FETCH_COUPONS_START';
export const FETCH_COUPONS_SUCCESS = 'FETCH_COUPONS_SUCCESS';
export const FETCH_COUPONS_FAILED = 'FETCH_COUPONS_FAILED';

export const FETCH_TABULAR_REPORT_START = 'FETCH_TABULAR_REPORT_START';
export const FETCH_TABULAR_REPORT_SUCCESS = 'FETCH_TABULAR_REPORT_SUCCESS';
export const FETCH_TABULAR_REPORT_FAILED = 'FETCH_TABULAR_REPORT_FAILED';

export const FILTER_TABULAR_REPORT_START = 'FILTER_TABULAR_REPORT_START';
export const FILTER_TABULAR_REPORT_SUCCESS = 'FILTER_TABULAR_REPORT_SUCCESS';
export const SAVE_TABULAR_REPORT_FILTERS = 'SAVE_TABULAR_REPORT_FILTERS';
export const FILTER_TABULAR_REPORT_FAILED = 'FILTER_TABULAR_REPORT_FAILED';

export const FETCH_EXPENSES_START = 'FETCH_EXPENSES_START';
export const FETCH_EXPENSES_SUCCESS = 'FETCH_EXPENSES_SUCCESS';
export const FETCH_EXPENSES_FAILED = 'FETCH_EXPENSES_FAILED';

export const DELETE_EXPENSE_START = 'DELETE_EXPENSE_START';
export const DELETE_EXPENSE_SUCCESS = 'DELETE_DEXPENSESUCCESS';
export const DELETE_EXPENSE_FAILED = 'DELETE_EXPENSE_FAILED';

export const UPDATE_EXPENSE_START = 'UPDATE_EXPENSE_START';
export const UPDATE_EXPENSE_SUCCESS = 'UPDATE_DEXPENSESUCCESS';
export const UPDATE_EXPENSE_FAILED = 'UPDATE_EXPENSE_FAILED';

export const SEARCH_EXPENSES_START = 'SEARCH_EXPENSES_START';
export const SEARCH_EXPENSES_SUCCESS = 'SEARCH_EXPENSES_SUCCESS';
export const SEARCH_EXPENSES_FAILED = 'SEARCH_EXPENSES_FAILED';

export const CREATE_EXPENSE_START = 'CREATE_EXPENSE_START';
export const CREATE_EXPENSE_SUCCESS = 'CREATE_EXPENSE_SUCCESS';
export const CREATE_EXPENSE_FAILED = 'CREATE_EXPENSE_FAILED';

export const FETCH_EXPENSES_CATEGORIES_START = 'FETCH_EXPENSES_CATEGORIES_START';
export const FETCH_EXPENSES_CATEGORIES_SUCCESS = 'FETCH_EXPENSES_CATEGORIES_SUCCESS';
export const FETCH_EXPENSES_CATEGORIES_FAILED = 'FETCH_EXPENSES_CATEGORIES_FAILED';

export const DELETE_EXPENSE_CATEGORY_START = 'DELETE_EXPENSE_CATEGORY_START';
export const DELETE_EXPENSE_CATEGORY_SUCCESS = 'DELETE_DEXPENSE_CATEGORYSUCCESS';
export const DELETE_EXPENSE_CATEGORY_FAILED = 'DELETE_EXPENSE_CATEGORY_FAILED';

export const UPDATE_EXPENSE_CATEGORY_START = 'UPDATE_EXPENSE_CATEGORY_START';
export const UPDATE_EXPENSE_CATEGORY_SUCCESS = 'UPDATE_DEXPENSE_CATEGORYSUCCESS';
export const UPDATE_EXPENSE_CATEGORY_FAILED = 'UPDATE_EXPENSE_CATEGORY_FAILED';

export const SEARCH_EXPENSES_CATEGORIES_START = 'SEARCH_EXPENSES_CATEGORIES_START';
export const SEARCH_EXPENSES_CATEGORIES_SUCCESS = 'SEARCH_EXPENSES_CATEGORIES_SUCCESS';
export const SEARCH_EXPENSES_CATEGORIES_FAILED = 'SEARCH_EXPENSES_CATEGORIES_FAILED';

export const CREATE_EXPENSE_CATEGORY_START = 'CREATE_EXPENSE_CATEGORY_START';
export const CREATE_EXPENSE_CATEGORY_SUCCESS = 'CREATE_EXPENSE_CATEGORY_SUCCESS';
export const CREATE_EXPENSE_CATEGORY_FAILED = 'CREATE_EXPENSE_CATEGORY_FAILED';

export const FETCH_EXPENSES_CUSTOMERS_START = 'FETCH_EXPENSES_CUSTOMERS_START';
export const FETCH_EXPENSES_CUSTOMERS_SUCCESS = 'FETCH_EXPENSES_CUSTOMERS_SUCCESS';
export const FETCH_EXPENSES_CUSTOMERS_FAILED = 'FETCH_EXPENSES_CUSTOMERS_FAILED';

export const DELETE_EXPENSE_CUSTOMER_START = 'DELETE_EXPENSE_CUSTOMER_START';
export const DELETE_EXPENSE_CUSTOMER_SUCCESS = 'DELETE_DEXPENSE_CUSTOMERSUCCESS';
export const DELETE_EXPENSE_CUSTOMER_FAILED = 'DELETE_EXPENSE_CUSTOMER_FAILED';

export const UPDATE_EXPENSE_CUSTOMER_START = 'UPDATE_EXPENSE_CUSTOMER_START';
export const UPDATE_EXPENSE_CUSTOMER_SUCCESS = 'UPDATE_DEXPENSE_CUSTOMER_SUCCESS';
export const UPDATE_EXPENSE_CUSTOMER_FAILED = 'UPDATE_EXPENSE_CUSTOMER_FAILED';

export const SEARCH_EXPENSES_CUSTOMERS_START = 'SEARCH_EXPENSES_CUSTOMERS_START';
export const SEARCH_EXPENSES_CUSTOMERS_SUCCESS = 'SEARCH_EXPENSES_CUSTOMERS_SUCCESS';
export const SEARCH_EXPENSES_CUSTOMERS_FAILED = 'SEARCH_EXPENSES_CUSTOMERS_FAILED';

export const CREATE_EXPENSE_CUSTOMER_START = 'CREATE_EXPENSE_CUSTOMER_START';
export const CREATE_EXPENSE_CUSTOMER_SUCCESS = 'CREATE_EXPENSE_CUSTOMER_SUCCESS';
export const CREATE_EXPENSE_CUSTOMER_FAILED = 'CREATE_EXPENSE_CUSTOMER_FAILED';

export const FETCH_EXPENSES_BANKS_START = 'FETCH_EXPENSES_BANKS_START';
export const FETCH_EXPENSES_BANKS_SUCCESS = 'FETCH_EXPENSES_BANKS_SUCCESS';
export const FETCH_EXPENSES_BANKS_FAILED = 'FETCH_EXPENSES_BANKS_FAILED';

export const DELETE_EXPENSE_BANK_START = 'DELETE_EXPENSE_BANK_START';
export const DELETE_EXPENSE_BANK_SUCCESS = 'DELETE_DEXPENSE_BANK_SUCCESS';
export const DELETE_EXPENSE_BANK_FAILED = 'DELETE_EXPENSE_BANK_FAILED';

export const UPDATE_EXPENSE_BANK_START = 'UPDATE_EXPENSE_BANK_START';
export const UPDATE_EXPENSE_BANK_SUCCESS = 'UPDATE_DEXPENSE_BANK_SUCCESS';
export const UPDATE_EXPENSE_BANK_FAILED = 'UPDATE_EXPENSE_BANK_FAILED';

export const SEARCH_EXPENSES_BANKS_START = 'SEARCH_EXPENSES_BANKS_START';
export const SEARCH_EXPENSES_BANKS_SUCCESS = 'SEARCH_EXPENSES_BANKS_SUCCESS';
export const SEARCH_EXPENSES_BANKS_FAILED = 'SEARCH_EXPENSES_BANKS_FAILED';

export const CREATE_EXPENSE_BANK_START = 'CREATE_EXPENSE_BANK_START';
export const CREATE_EXPENSE_BANK_SUCCESS = 'CREATE_EXPENSE_BANK_SUCCESS';
export const CREATE_EXPENSE_BANK_FAILED = 'CREATE_EXPENSE_BANK_FAILED';

export const FETCH_UNITS_START = 'FETCH_UNITS_START';
export const FETCH_UNITS_SUCCESS = 'FETCH_UNITS_SUCCESS';
export const FETCH_UNITS_FAILED = 'FETCH_UNITS_FAILED';

export const DELETE_UNIT_START = 'DELETE_UNIT_START';
export const DELETE_UNIT_SUCCESS = 'DELETE_UNIT_SUCCESS';
export const DELETE_UNIT_FAILED = 'DELETE_UNIT_FAILED';

export const SEARCH_UNITS_START = 'SEARCH_UNITS_START';
export const SEARCH_UNITS_SUCCESS = 'SEARCH_UNITS_SUCCESS';
export const SEARCH_UNITS_FAILED = 'SEARCH_UNITS_FAILED';

export const UPDATE_UNIT_START = 'UPDATE_UNIT_START';
export const UPDATE_UNIT_SUCCESS = 'UPDATE_UNIT_SUCCESS';
export const RESET_UPDATE_UNIT_SUCCESS = 'RESET_UPDATE_UNIT_SUCCESS';
export const UPDATE_UNIT_FAILED = 'UPDATE_UNIT_FAILED';

export const ADD_UNIT_START = 'ADD_UNIT_START';
export const ADD_UNIT_SUCCESS = 'ADD_UNIT_SUCCESS';
export const RESET_ADD_UNIT_SUCCESS = 'RESET_ADD_UNIT_SUCCESS';
export const ADD_UNIT_FAILED = 'ADD_UNIT_FAILED';

export const FETCH_PERMISSIONS_START = 'FETCH_PERMISSIONS_START';
export const FETCH_PERMISSIONS_SUCCESS = 'FETCH_PERMISSIONS_SUCCESS';
export const FETCH_PERMISSIONS_FAILED = 'FETCH_PERMISSIONS_FAILED';


export const ADD_TO_SERVICES = 'ADD_TO_SERVICES';
export const REMOVE_SERVICE = 'REMOVE_SERVICE';
export const ADD_TO_DEALS = 'ADD_TO_DEALS';
export const REMOVE_DEAL = 'REMOVE_DEAL';
export const RESET_CART = 'RESET_CART';